
import { defineComponent, ref, Ref, watch } from 'vue';
import {
    listPagination,
    ListHeaderItem,
    RequestData,
    ListActionItem,
    getSearch
} from '@/components/common/list';
import { access, community } from '@/data';
import addButton from '@/components/common/add-button/index.vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import remove from '@/methods/remove-func';
import HttpRequest from '@/util/axios.config';

const { repeatLabels } = access;
const headers: Array<ListHeaderItem> = [{
    name: 'ID',
    label: WordList.ProperAllTextAccessGroupID
}, {
    name: 'Name',
    label: WordList.ProperAllTextAccessGroup,
    type: 'customize'
}, {
    name: 'User',
    label: WordList.ProperAllTextPeople
}, {
    name: 'Device',
    label: WordList.TabelUpdateBoxDevice
}, {
    name: 'Repeat',
    label: WordList.ProperAllTextRepeats,
    type: 'customize'
}, {
    name: 'Time',
    label: WordList.ProperAllTextTimeDuration,
    type: 'customize'
}];

export default defineComponent({
    components: {
        listPagination,
        addButton,
        propertyBreadCrumb
    },
    setup() {
        const breadCrumbHeaders = [{ label: WordList.ProperAllTextAccessGroup }];
        const { row, page } = getSearch({ row: '', page: '' });

        const enableRequest = ref(false);
        const listRequestData: Ref<RequestData> = ref({
            url: `v3/web/${community.ProjectType.value}/access/getList`,
            param: getSearch({
                searchValue: '',
                searchKey: 'all'
            })
        });
        const saveParamInPath = ref(false);
        const updateToList = ref(0);
        const search = () => {
            updateToList.value += 1;
            saveParamInPath.value = true;
        };

        const action: Array<ListActionItem> = [{
            type: 'view',
            event(value: { ID: string }) {
                router.push(`/${propertyBaseRouter}/${propertySubRouter.accessInfo}?id=${value.ID}`);
            },
            class: 'modify-icon el-icon-my-eye',
            title: WordList.ProperAllTextView
        }, {
            type: 'delete2',
            event(value: { ID: string; User: number }) {
                let message = '';
                if (value.User === 0) message = WordList.ProperAllTextDeleteAccessTips;
                else message = WordList.ProperAllTextDeleteAccessTips2;
                remove(message, () => {
                    HttpRequest.post(`v3/web/${community.ProjectType.value}/access/delete`, {
                        ID: value.ID
                    }, () => {
                        search();
                    });
                });
            },
            class: 'el-icon-delete delete-icon',
            showCondition(value) {
                return value.UnitID === '0';
            },
            title: WordList.TabelConfigInHtmlDelete
        }];
        const add = () => {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.setAccess}`);
        };

        watch(() => [community.ProjectType.value, community.communityInfoRequested.value], () => {
            listRequestData.value.url = `v3/web/${community.ProjectType.value}/access/getList`;
            if (community.communityInfoRequested.value) {
                enableRequest.value = true;
                updateToList.value += 1;
            }
        }, {
            immediate: true
        });

        return {
            breadCrumbHeaders,
            headers,
            listRequestData,
            row,
            page,
            updateToList,
            repeatLabels,
            action,
            search,
            add,
            saveParamInPath,
            enableRequest
        };
    }
});
